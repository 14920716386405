

import { useParams } from "react-router"
import { Link } from "react-router-dom";
import { Blowfish } from 'javascript-blowfish';
import { useContext, useState } from "react";

import colorContrast from 'color-contrast';

import SocketContext from "../context/socket";
import md5 from "md5";
import { ClipLoader } from "react-spinners";

import { Helmet } from "react-helmet";
import config from "../config";

const DecryptError = (props) => (
    <span
        className="decrypt-error"
        title="Unable to decrypt this user's message. Check if the user has the correct password."
    >
        &lt;Unable to decrypt{!props.simple && ( " - this user won't be able to see your messages." )}&gt;
    </span>
)

function Chat(props) {

    const { id } = useParams();
    const color = "#" + md5(id).substring(0, 6);

    const textColor = colorContrast("#FFF", color) >= 4.5 ? "white" : "black";

    const socket = useContext(SocketContext);
    const [ active, setActive ] = useState(false);
    console.log(active);

    const [ messages, setMessages ] = useState([]);
    const [ blowfish, setBlowfish ] = useState(null);

    const [ inputPassword, setInputPw ] = useState(
        Buffer.from(window.location.hash.substring(1), "base64").toString("utf8")
    );
    const [ inputUser, setIU ] = useState("");
    const [ showForm, setSF ] = useState(true);
    const [ msgInput, setMsgInput ] = useState("");

    socket.on("connect", () => setActive(true));
    socket.on("change_username", setIU);

    socket.on("disconnect", () => setActive(false));

    const emitMsg = (m) => {

        if (m.length < 1) { return; }

        const message = {
            id: id,
            username: inputUser,
            content: blowfish.encrypt(m),
            flag: blowfish.encrypt(id)
        }

        setMessages(
            prev => [
                ...prev,
                message
            ]
        )

        socket.emit("message", message);

        setMsgInput("");

    }

    const setPassword = () => {

        console.log("Setting BF encryption with password", inputPassword)

        window.location.hash = Buffer.from(inputPassword).toString("base64");

        setBlowfish(
            new Blowfish(inputPassword)
        );

        socket.on("message", data => {

            console.log(data);

            setMessages(
                prev => [
                    ...prev,
                    {
                        ...data
                    }
                ]
            )
    
        });

        console.dir(new Blowfish(inputPassword));

        socket.emit("initial_data", {
            id: id
        });

        setSF(false);

    }

    const inputKeyDown = (e) => {

        if (e.key === "Enter") {
            emitMsg(e.target.value);
        }

    }

    return (

        <>

            <Helmet>
                <title>#{id} on { config.product_name }</title>
            </Helmet>

            <h1 className="welcome">Welcome to channel <div style={{backgroundColor: color, color: textColor}} className="title">#{id}</div></h1>

            {
                showForm ? (

                    <div className="introContainer">

                    <p>
                        You must decrypt this chat first before access.<br/>
                        <span className="sml">
                            Note: if you enter an incorrect password, you can still access the chat but the content will be corrupted.
                            <b>You will not be notified if the password is incorrect</b>.<br/>
                        </span>
                    </p>

                    <p className="sml">
                        Not sure what to do? Learn <Link to="/pages/how-ultramarine-works">how {config.product_name} works</Link>.
                    </p>

                    <label>
                        Preferred username<br/>
                        <input type="text" minLength={3} value={inputUser} onInput={(e) => setIU(e.target.value)} />
                    </label>

                    <label>
                        Room password<br/>
                        <input type="password" value={inputPassword} onInput={(e) => setInputPw(e.target.value)} />
                    </label>

                    <button onClick={setPassword} className="btn blue sml">Setup chat</button>

                    </div>

                ) : (true ? (

                    <>

                    <p>
                        Chat is now active and <b>end-to-end encrypted</b>. If a client joins without the correct password, you'll see a <DecryptError simple={true} /> error message.<br/><br/>
                        Share this link and the password with others and they'll be able to join too!
                    </p>

                    <div className="messages">

                        {
                            messages && messages.map((m,i) => (
                                <div className="message" key={i} style={{
                                    flexFlow: `row${m.username === inputUser ? "-reverse":""}`,
                                    backgroundColor: m.username === inputUser ? "#EEE":"white"
                                }}>
                                    <img title={`${m.username} - message ID ${m.msg_id}`} alt={`Profile pic for ${m.username}`} src={`https://cdn.statically.io/avatar/s=50/${encodeURIComponent(m.username)}`} />
                                    <span className="text">{ m.flag === blowfish.encrypt(id) ? blowfish.decrypt(m.content) : <DecryptError /> }</span>
                                </div>
                            ))
                        }

                    </div>

                    <div className="send">

                        <input type="text" className="messageInput" minLength={1} maxLength={280} value={msgInput} onKeyDown={inputKeyDown} onInput={e => setMsgInput(e.target.value)} />
                        <button className="btn green" onClick={() => emitMsg(msgInput)} >Send</button>

                    </div>

                    </>

                ) : (
                    <div className="loading">
                        <ClipLoader /> Connecting, please wait...
                    </div>
                ))
            }

        </>

    )

}

export default Chat