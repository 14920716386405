import Chat from './components/Chat';
import NewChat from './components/NewChat';
import SocketContext, { socket } from './context/socket';
import MainPage from './components/MainPage';
import Help from './components/Help';
import NotFound from './components/NotFound';

import './App.css';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import config from './config';

function App() {

  return (

    <>

    <Router>

      <header>
        <Link to="/"><img src="/logotype.webp" alt={`${config.product_name} logotype`} /></Link>
      </header>

      <Switch>

        <Route path="/chat/:id">

          <SocketContext.Provider value={socket}>
            <Chat />
          </SocketContext.Provider>

        </Route>

        <Route path="/pages/:slug">
          <Help />
        </Route>

        <Route path="/chat/">
          <NewChat />
        </Route>

        <Route exact path="/">
          <MainPage />
        </Route>

        <Route path="*">
          <NotFound />
        </Route>

      </Switch>

    </Router>

    <footer>
      <p>{config.product_name} is an <a href="//srg.id.au">srg</a> project.</p>
    </footer>

    </>

  );

}

export default App;
