import { useHistory, Link } from "react-router-dom";
import randomWords from 'random-words';
import { useState } from "react";

import slugify from 'react-slugify';

function NewChat(props) {

    const history = useHistory();
    const [name, setName] = useState(randomWords({
        exactly: 3,
        join: "-"
    }));

    const setNameOnInput = (e) => {
        setName(e.target.value)
    }

    const createNew = () => {

        return history.push("/chat/" + (name.length > 0 ? slugify(name) : randomWords({
            exactly: 3,
            join: "-"
        })));

    }

    return (
        <div class="newChatWidget">

        <label>

            Name of your chat<br/>
            <input value={name} style={{marginBottom: "7px"}} onInput={setNameOnInput} placeholder="my-cool-chat" type="text" />

        </label>

        <button onClick={createNew} className="btn blue sml">{props.text || "Create a new chat"}</button><br/>

        <p>
            Or, <Link to="/pages/how-ultramarine-works">learn how it all works</Link>.
        </p>

        </div>
    )

}

export default NewChat