import config from "../config"
import NewChat from "./NewChat"
import { Helmet } from "react-helmet"

function MainPage(props) {
    return (
        <>

        <Helmet>
            <title>{config.product_name}</title>
        </Helmet>

        <main>

            <div>

                <h1>Welcome to { config.product_name }</h1>
                <h3>The simplest secure texting app.</h3>

                <ul>
                    <li>No accounts</li>
                    <li>No data collection</li>
                    <li>No spoofing</li>
                    <li><b>All the privacy</b></li>
                </ul><br/>  

                <NewChat />

            </div>

        </main>
        </>
    )
}

export default MainPage