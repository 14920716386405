import { useLocation, Link } from "react-router-dom"

function NotFound(props) {

    const location = useLocation();

    return (
        <div className="notFound">

            <h2>Sorry, the requested path was not found.</h2>
            <p>
                Looking for the chat <code>{location.pathname}</code>? Just click <Link to={`/chat${location.pathname}`}>here</Link>. Alternatively, <Link to="/">go back home</Link>.
            </p>

        </div>
    )
}

export default NotFound