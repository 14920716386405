import { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom"
import ReactMarkdown from "react-markdown";
import { Helmet } from "react-helmet";

function Help(props) {

    const { slug } = useParams();
    const [pageContent, setContent] = useState(null);

    useEffect(() => {

        fetch(`https://raw.githubusercontent.com/shaunakg/ultramarine-help/main/${slug}.md`).then(r => r.text()).then(setContent).catch(e => {
            setContent("**There was an error loading this help page.**");
        }) 

    }, [slug]);

    return (
        <>

        <Helmet>
            <title>Ultramarine Info Center</title>
        </Helmet>

        {
            pageContent ? (
                <ReactMarkdown>{pageContent}</ReactMarkdown>
            ) : ( <b>Loading...</b> )
        }

        <div class="helpThanksForReading">
            Thanks for reading. You can <Link to="/">go back home</Link>, or if you have more questions, <a href="mailto:hello@srg.id.au">email us</a>.
        </div>

        </>
    );

}

export default Help